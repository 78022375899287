import { ClassNames } from '../Interfaces/class-names';
import { Options } from '../Interfaces/options';
import { wrap } from '../Helpers/wrap';
import SearchHeading from './SearchHeading';
import templates from '../Helpers/templates';
import SearchStatus, { Status } from './SearchStatus';
import DomainSearch from './DomainSearch';
import SearchResultCaption from './SearchResultCaption';
import DomainChecker from '../index';
import { DomainResponse, DomainSuggestions } from '../Helpers/Fetch';
import SearchAlternatives from './SearchAlternatives';
import { Locale } from '../Interfaces/locale';

export default class PopupContainer {
    public readonly element: HTMLElement;
    private readonly domainsElement: HTMLElement;
    private readonly cartElement: HTMLElement;

    private readonly classNames: ClassNames;
    private readonly container: HTMLElement;
    private heading: SearchHeading;
    private status: SearchStatus;
    private readonly locale: Locale;
    private readonly fetchURL: string;
    private domainSearch: DomainSearch;
    private searchResultText: SearchResultCaption;
    private closeIcon: HTMLRsIconElement;
    private domainAlternatives: SearchAlternatives;
    private domainPreferred: SearchAlternatives;


    public constructor({
                           element,
                           config: { classNames, container, fetchURL },
                           locale
                       }: {
        element: HTMLElement;
        config: Options;
        locale: Locale
    }) {
        this.fetchURL = fetchURL;
        this.locale = locale;
        this.container = document.querySelector(container) ?? document.body;
        this.classNames = classNames;
        this.element = element;
        this.domainsElement = this.getTemplate('domainContainer');
        // todo shopping cart to go in here
        this.cartElement = this.getTemplate('cartContainer');
        wrap(this.domainsElement, this.element);
        wrap(this.cartElement, this.element);

        this.build();
    }

    public open(): this {
        wrap(this.element, document.body);
        setTimeout(() => {
            this.element.classList.add(this.classNames.popupContainerActive);
        });
        document.querySelector('#shadow')?.classList.add('dc__checker-open');
        return this;
    }

    public close(): this {
        document.querySelector('#shadow')?.classList.remove('dc__checker-open');
        this.element.classList.remove(this.classNames.popupContainerActive);
        setTimeout(() => {
            this.element.remove();
            this.resetLoadingUi();
        }, 150);
        return this;
    }

    public setStatus(status?: Status): this {
        this.status.setStatus(status);
        return this;
    }

    public setHeading(heading: string): this {
        this.heading.heading = heading;
        return this;
    }

    public setPrice(price?: string): this {
        this.searchResultText.price = price;
        return this;
    }

    public setCaptionMessage(message: string): this {
        this.searchResultText.setMessage(message);
        return this;
    }

    public setCanClaim(state: boolean): this {
        this.domainSearch.setCanClaim(state);
        return this;
    }

    public setDomain(domain: string): this {
        this.domainSearch.setDomain(domain);
        return this;
    }

    public showAndFetchDomainRows(domainDetail: DomainResponse): this {
        const suggestions = domainDetail.domainSuggestions;
        if (domainDetail.isAvailable) {
            this.domainPreferred.element.style.display = 'block';
            const domain = this.domainSearch.input.value;
            const preferredSuggestion: DomainSuggestions = {
                prefix: domain.split('.')[0],
                suffixes: [domain.substring(domain.indexOf('.'))]
            };
            // This shows the preferred domain
            this.domainPreferred.showDomains(preferredSuggestion);
        } else {
            this.hidePreferredDomain();
        }
        // This shows the alternative domains
        this.domainAlternatives.showDomains(suggestions);
        return this;
    }
    private hidePreferredDomain(): void {
        this.domainPreferred.element.style.display = 'none';
    }

    private resetLoadingUi(message = 'Checking your domain'): void {
        this.setHeading(message);
        this.setPrice(null);
        this.setCanClaim(false);
    }

    private getTemplate(template: keyof typeof templates, ...args: any): any {
        return templates[template].call(this, { classNames: this.classNames }, ...args);
    }

    private build(): void {
        this.closeIcon = this.getTemplate('icon', this.classNames.popupClose);
        this.closeIcon.setAttribute('name', 'x');
        this.closeIcon.setAttribute('size', '24');
        this.heading = new SearchHeading({
            element: this.getTemplate('searchHeading')
        });

        this.status = new SearchStatus({
            classNames: this.classNames
        });

        const searchContainer = Object.assign(document.createElement('div'), {
            className: this.classNames.popupSearchContainer
        });

        this.searchResultText = new SearchResultCaption({
            element: this.getTemplate('searchResultText'),
            classNames: this.classNames
        });

        this.domainSearch = new DomainSearch({
            element: this.getTemplate('popupSearch'),
            config: {
                classNames: this.classNames,
                fetchURL: this.fetchURL
            },
            locale: this.locale
        });

        this.domainPreferred = new SearchAlternatives({
            element: this.getTemplate('searchResultsContainer', this.locale, 'Your preferred Domain', 'preferred'),
            classNames: this.classNames,
            locale: this.locale,
            rowType: 'preferred'
        });

        this.domainAlternatives = new SearchAlternatives({
            element: this.getTemplate('searchResultsContainer', this.locale),
            classNames: this.classNames,
            locale: this.locale,
            rowType: 'alternative'
        });

        wrap(this.closeIcon, this.domainsElement);
        wrap(this.status.element, this.domainsElement);
        wrap(this.heading.element, this.domainsElement);
        wrap(this.searchResultText.element, this.domainsElement);
        wrap(this.domainSearch.element, searchContainer);
        wrap(searchContainer, this.domainsElement);
        wrap(this.domainPreferred.element, this.domainsElement);
        wrap(this.domainAlternatives.element, this.domainsElement);


        this.domainSearch.element.addEventListener('domainSearchStart', event => {
            this.setStatus('loading');
            this.resetLoadingUi();
            event.stopImmediatePropagation();
        });

        this.domainSearch.element.addEventListener('domainSearchFinish', (event: CustomEvent<DomainResponse>) => {
            event.stopImmediatePropagation();
            DomainChecker.setPopupStatus(this, event.detail);
            this.showAndFetchDomainRows(event.detail);
        });

        this.closeIcon.addEventListener('click', () => {
            this.close();
        });

        this.domainSearch.input.addEventListener('input', () => {
            this.resetLoadingUi('Enter a domain to register');
            this.setStatus(null);
        });

        this.domainSearch.element.addEventListener('domainSearchAgain', () => {
            this.resetLoadingUi('Enter a domain to register');
            this.setStatus(null);
            this.domainSearch.input.value = '';
            this.domainSearch.input?.focus();
        });
    }
}
