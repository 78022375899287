import templates from '../Helpers/templates';
import { ClassNames } from '../Interfaces/class-names';

export type Status = 'loading' | 'available' | 'unavailable';

export default class SearchStatus {
    public element: HTMLElement;
    private readonly searchLoader: HTMLRsHeaderElement;
    private readonly icon: HTMLRsIconElement;
    private readonly classNames: ClassNames;

    public constructor({ classNames }: {
        classNames: ClassNames
    }) {
        this.classNames = classNames;
        this.searchLoader = this.getTemplate('searchLoader');
        this.icon = this.getTemplate('icon');
        this.element = this.searchLoader;
    }

    public setStatus(status?: Status): this {
        if (status === null) {
            this.icon.setAttribute('color', 'white');
            this.element.replaceWith(this.icon);
            this.element = this.icon;
        } else if (status === 'available') {
            this.icon.setAttribute('name', 'circle-tick');
            this.icon.setAttribute('color', 'positive-700');
            this.element.replaceWith(this.icon);
            this.element = this.icon;
        } else if (status === 'unavailable') {
            this.icon.setAttribute('name', 'warning');
            this.icon.setAttribute('color', 'negative');
            this.element.replaceWith(this.icon);
            this.element = this.icon;
        } else if (status === 'loading') {
            this.element.replaceWith(this.searchLoader);
            this.element = this.searchLoader;
        }

        return this;
    }

    private getTemplate(template: keyof typeof templates, ...args: any): any {
        return templates[template].call(this, { classNames: this.classNames }, ...args);
    }
}
