export default class SearchHeading {
    public set heading(value: string) {
        this._heading = value;
        this.element.textContent = this._heading;
    }

    public readonly element: HTMLRsHeaderElement;
    private _heading = 'Checking your domain';

    public constructor({
                           element
                       }: {
        element: HTMLRsHeaderElement;
    }) {
        this.element = element;

        this.element.textContent = this._heading;
    }
}
