import { load } from 'recaptcha-v3';
import DomainChecker from '../index';

export interface DomainSuggestions {
    prefix: string;
    suffixes: string[];
}

export interface DomainResponse {
    currency: string;
    taxLabel: string;
    isAvailable: boolean;
    formattedPrice: string;
    formattedYearlyPrice: string | null;
    success: boolean;
    error: string;
    domainSuggestions: DomainSuggestions;
}

export const FetchDomain = async (domainName: string, locale: string, limit = 5): Promise<DomainResponse> => {
    const url = window.location.hostname.includes('rocketspark.com') ? DomainChecker.PRODUCTION_FETCH : DomainChecker.DEVELOPMENT_FETCH;
    try {
        const recaptcha = await load('6Lejtv4fAAAAAMB4Q6niV4sWFo7deAYes9M9ikNT');
        const token = await recaptcha.execute('domainRegister');
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                countryCode: locale,
                domain: domainName,
                suggestionsLimit: limit.toString(),
                'g-recaptcha-response': token
            })
        });
        return response.json();
    } catch (err) {
        console.error(err);
        throw new Error(err);
    }
};
