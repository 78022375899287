import { ClassNames } from '../Interfaces/class-names';
import { Options } from '../Interfaces/options';

const namespace = 'dc__';

const DEFAULT_CLASSNAMES: ClassNames = {
    element: `${ namespace }checker`,
    containerOuter: `${ namespace }container`,
    inputContainer: `${ namespace }input-container`,
    inputContainerSearching: `${ namespace }input-container--searching`,
    domainInput: `${ namespace }domain-input`,
    searchButton: `${ namespace }search-button`,

    popupContainer: `${ namespace }popup-container`,
    domainContainer: `${ namespace }domain-container`,
    cartContainer: `${ namespace }cart-container`,
    popupContainerActive: `${ namespace }popup-container--active`,
    popupSearchContainer: `${ namespace }popup-search-container`,
    popupClose: `${ namespace }popup-close`,
    popupSearchSpinner: `${ namespace }popup-search-spinner`,
    searchHeading: `${ namespace }search-heading`,
    searchAgain: `${ namespace }search-again`,
    searchAgainActive: `${ namespace }search-again--active`,
    searchResultCaption: `${ namespace }search-result-caption`,
    searchResultCaptionPrice: `${ namespace }search-result-caption__price`,
    searchResultCaptionActive: `${ namespace }search-result-caption--active`,

    searchAlternativeContainer: `${ namespace }search-alternatives`,
    searchAlternativeContainerTitle: `${ namespace }search-alternatives__title`,
    searchAlternativeContainerContent: `${ namespace }search-alternatives__content`,
    searchAlternativeContainerActive: `${ namespace }search-alternatives--active`,
    searchAlternativeHeader: `${ namespace }search-alternatives__header`,
    searchPreferredHeader: `${ namespace }search-alternatives__preferred-header`,
    searchAlternativeRow: `${ namespace }search-alternatives__row`,
    searchPreferredRow: `${ namespace }search-preferred__row`,
    searchPreferredPill: `${ namespace }search-preferred__pill`,

    searchAlternativeRowUnavailable: `${ namespace }search-alternatives__row--unavailable`,
    searchAlternativeRowFetching: `${ namespace }search-alternatives__row--fetching`,
    searchAlternativeRowHeader: `${ namespace }search-alternatives__row-header`,
    searchAlternativeRowHeaderContainer: `${ namespace }search-alternatives__row-header-container`,
    searchAlternativeDomainSuffix: `${ namespace }search-alternatives__domain-suffix`,
    searchAlternativePricingContainer: `${ namespace }search-alternatives__pricing-container`,
    searchAlternativePricing: `${ namespace }search-alternatives__pricing`,
    searchAlternativePricingDiscount: `${ namespace }search-alternatives__pricing-discount`,
    searchAlternativePricingBlurb: `${ namespace }search-alternatives__pricing-blurb`,
    searchAlternativeGetItNowContainer: `${ namespace }search-alternatives__get-it-now-container`,
    searchAlternativeGetItNow: `${ namespace }search-alternatives__get-it-now`,
    searchAlternativeSpinner: `${ namespace }search-alternative__spinner`,
};

export const DEFAULT_CONFIG: Options = {
    classNames: DEFAULT_CLASSNAMES,
    container: '.stack',
    fetchURL: null
};
