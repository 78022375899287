import { ClassNames } from '../Interfaces/class-names';
import DomainChecker from '../index';
import { FetchDomain } from '../Helpers/Fetch';
import { wrap } from '../Helpers/wrap';
import { Locale } from '../Interfaces/locale';

export default class SearchAlternativeRow {
  public readonly element: HTMLRsHeaderElement;
  private readonly classNames: ClassNames;
  private readonly header: HTMLRsHeaderElement;
  private readonly prefix: string;
  private readonly suffix: string;
  private getItNow: HTMLSpanElement;
  private readonly domain: string;
  private locale: Locale;
  private isAvailable = false;
  private pricing: HTMLDivElement;
  private blurb: HTMLSpanElement;

  public constructor({
    element,
    prefix,
    suffix,
    classNames,
    locale,
  }: {
    element: HTMLRsHeaderElement;
    prefix: string;
    suffix: string;
    classNames: ClassNames;
    locale: Locale;
  }) {
    this.element = element;
    this.prefix = prefix;
    this.suffix = suffix;
    this.locale = locale;
    this.domain = this.prefix + this.suffix;
    this.classNames = classNames;
    this.header = this.element.querySelector(
      `.${this.classNames.searchAlternativeRowHeader}`
    );
    this.getItNow = this.element.querySelector(
      `.${this.classNames.searchAlternativeGetItNow}`
    );
    this.pricing = this.element.querySelector(
      `.${this.classNames.searchAlternativePricingContainer}`
    );

    this.build();
    this.fetchDetails();
  }

  private build(): void {
    this.header.innerHTML = this.buildDomain();
  }

  private buildDomain(): string {
    return `${this.prefix}<span class="${this.classNames.searchAlternativeDomainSuffix}">${this.suffix}</span>`;
  }

  private addListeners(): void {
    this.element.addEventListener('click', () => {
      const url = window.location.hostname.includes('rocketspark.com')
        ? DomainChecker.PRODUCTION_GET_IT_NOW
        : DomainChecker.DEVELOPMENT_GET_IT_NOW;
      window.location.href = url + this.domain;
    });
  }

  private fetchDetails(): void {
    FetchDomain(this.domain, this.locale, 0)
      .then(
        ({ isAvailable, formattedPrice, formattedYearlyPrice, success }) => {
          if (!success) {
            this.element.remove();
          }

          this.element.classList.remove(
            this.classNames.searchAlternativeRowFetching
          );
          this.isAvailable = isAvailable;
          if (!isAvailable) {
            this.element.classList.add(
              this.classNames.searchAlternativeRowUnavailable
            );
            this.getItNow.textContent = 'UNAVAILABLE';
            this.getItNow.style.removeProperty('color');
            this.getItNow.style.setProperty('background', 'transparent');
            this.getItNow.setAttribute('disabled', 'true');
            return;
          }

          if (formattedYearlyPrice && formattedYearlyPrice !== formattedPrice) {
            this.pricing.innerHTML = `
                <div><span class="${this.classNames.searchAlternativePricingDiscount}">${formattedYearlyPrice}</span> <span class="${this.classNames.searchAlternativePricing}">${formattedPrice}</span></div>
            `;

            this.blurb = Object.assign(document.createElement('div'), {
                textContent: 'For the first year',
                className: this.classNames.searchAlternativePricingBlurb,
              });
          } else {
            this.pricing.innerHTML = `
                <div class="${this.classNames.searchAlternativePricing}">${formattedPrice}</div>
            `;
            this.blurb = Object.assign(document.createElement('div'), {
                textContent: 'Per year',
                className: this.classNames.searchAlternativePricingBlurb,
              });
          }
          wrap(this.blurb, this.pricing);

          this.addListeners();
        }
      )
      .catch(() => {
        this.element.remove();
      });
  }
}
