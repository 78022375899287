import { Options } from '../Interfaces/options';
import { wrap } from './wrap';
import { ClassNames } from '../Interfaces/class-names';
import { Locale, Currency } from '../Interfaces/locale';

const templates = {
    container({ classNames: { containerOuter } }: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: containerOuter,
        });
    },
    inputContainer({ classNames: { inputContainer } }: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: inputContainer,
        });
    },
    domainSearch({ classNames: { inputContainer, domainInput, searchButton }, }: Options): HTMLDivElement {
        const container = Object.assign(document.createElement('div'), {
            className: inputContainer,
        });

        const input = Object.assign(document.createElement('input'), {
            className: domainInput,
            placeholder: 'Search for a domain',
        });

        const button = Object.assign(document.createElement('div'), {
            className: searchButton,
        });

        const button_container = Object.assign(document.createElement('div'), {
            className: 'custombutt_block'
        });

        const button_content = Object.assign(document.createElement('div'), {
            className: 'button-content custombutt_link',
            textContent: 'Search'
        });

        button_container.appendChild(button_content);

        button.appendChild(button_container);

        wrap(input, container);
        wrap(button, container);

        return container;
    },
    popupSearch({ classNames: { inputContainer, domainInput, searchButton }, }: Options): HTMLDivElement {
        const container = Object.assign(document.createElement('div'), {
            className: inputContainer,
        });

        const input = Object.assign(document.createElement('input'), {
            className: domainInput,
            placeholder: 'Search for a domain',
        });

        const button = Object.assign(document.createElement('rs-button'), {
            className: searchButton,
            innerText: 'Search'
        });

        button.setAttribute('color', 'grey-light');

        wrap(input, container);
        wrap(button, container);

        return container;
    },
    popupContainer({ classNames: { popupContainer }, }: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: `${ popupContainer } rs-component-shadow--popup`,
        });
    },
    domainContainer({ classNames: { domainContainer }, }: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: domainContainer,
        });
    },
    cartContainer({ classNames: { cartContainer }, }: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: cartContainer,
        });
    },
    searchHeading({ classNames: { searchHeading }, }: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: searchHeading
        });
    },
    searchLoader({ classNames: { popupSearchSpinner }, }: Options): HTMLRsSpinnerElement {
        const spinner = Object.assign(document.createElement('rs-spinner'), {
            className: popupSearchSpinner
        });
        spinner.setAttribute('size', 'small');
        return spinner;
    },
    icon(classNames: Partial<ClassNames>, className: string): HTMLRsIconElement {
        const icon = Object.assign(document.createElement('rs-icon'), {
            className
        });
        icon.setAttribute('size', '32');
        return icon;
    },
    searchResultText({ classNames: { searchResultCaption }, }: Options): HTMLDivElement {
        return Object.assign(document.createElement('div'), {
            className: searchResultCaption
        });
    },
    searchResultsContainer({ classNames: { searchAlternativeContainer, searchAlternativeHeader, searchPreferredHeader, searchAlternativeContainerTitle, searchAlternativeContainerContent, } }: Options, locale: Locale, title = 'Other great alternatives', rowType: 'preferred' | 'alternative'): HTMLDivElement {
        const headerContainer = Object.assign(document.createElement('div'), {
            className: rowType === 'preferred' ? searchPreferredHeader : searchAlternativeHeader
        });
        const header = Object.assign(document.createElement('div'), {
            className: searchAlternativeContainerTitle,
            textContent: title,
        });
        const content = Object.assign(document.createElement('div'), {
            className: searchAlternativeContainerContent,
            textContent: `Prices are in ${ Currency.currency[locale] } and exclude ${ Currency.taxLabel[locale] }`,
        });
        const container = Object.assign(document.createElement('div'), {
            className: searchAlternativeContainer
        });
        wrap(header, headerContainer);
        wrap(content, headerContainer);
        wrap(headerContainer, container);

        return container;
    },
    searchAlternativeRow({
                             classNames: {
                                 searchAlternativeRow,
                                 searchPreferredRow,
                                 searchPreferredPill,
                                 searchAlternativeRowHeader,
                                 searchAlternativeRowHeaderContainer,
                                 searchAlternativeGetItNow,
                                 searchAlternativeRowFetching,
                                 searchAlternativeSpinner,
                                 searchAlternativeGetItNowContainer,
                                 searchAlternativePricingContainer
                             }
                         }: Options, rowType: 'preferred' | 'alternative'): HTMLDivElement {
        const headerContainer = Object.assign(document.createElement('div'), {
            className: searchAlternativeRowHeaderContainer
        });
        const header = Object.assign(document.createElement('div'), {
            className: searchAlternativeRowHeader
        });
        if (rowType === 'preferred') {
            const pill = Object.assign(document.createElement('span'), {
                className: searchPreferredPill,
                textContent: 'It\'s Available'
            });
            wrap(pill, headerContainer);
        }
        wrap(header, headerContainer);
        const price = Object.assign(document.createElement('div'), {
            className: searchAlternativePricingContainer
        });

        const getItNowContainer = Object.assign(document.createElement('div'), {
            className: searchAlternativeGetItNowContainer
        });

        const spinner = Object.assign(document.createElement('rs-spinner'), {
            className: `${ searchAlternativeSpinner }`
        });
        spinner.setAttribute('size', 'small');

        wrap(spinner, getItNowContainer);

        const getItNowButton = Object.assign(document.createElement('div'), {
            textContent: 'GET IT NOW',
            className: searchAlternativeGetItNow
        });
        if (rowType === 'preferred') {
            getItNowButton.style.setProperty('background', 'var(--rs-color-secondary-variant)');
            getItNowButton.style.setProperty('color', 'var(--rs-color-select-background)');
        } else {
            getItNowButton.style.setProperty('background', '#F2EBE5');
            getItNowButton.style.setProperty('color', 'var(--rs-color-secondary-variant)');
        }


        wrap(getItNowButton, getItNowContainer);

        const row = Object.assign(document.createElement('div'), {
            className: `${ searchAlternativeRow } ${rowType === 'preferred' ? `${searchPreferredRow}` : ''} ${ searchAlternativeRowFetching }`
        });
        wrap(headerContainer, row);
        wrap(price, row);
        wrap(getItNowContainer, row);

        return row;
    }
};

export default templates;
