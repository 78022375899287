export type Locale = 'NZ' | 'AU' | 'UK' | 'US';
export const Currency = {
    currency: {
        ['NZ']: 'NZD',
        ['AU']: 'AUD',
        ['UK']: 'GBP',
        ['US']: 'USD'
    },
    taxLabel: {
        ['NZ']: 'GST',
        ['AU']: 'GST',
        ['UK']: 'VAT',
        ['US']: 'GST'
    },
};