import { ClassNames } from '../Interfaces/class-names';
import SearchAlternativeRow from './SearchAlternativeRow';
import templates from '../Helpers/templates';
import { wrap } from '../Helpers/wrap';
import { DomainSuggestions } from '../Helpers/Fetch';
import { Locale } from '../Interfaces/locale';

export default class SearchAlternatives {
    public readonly element: HTMLRsHeaderElement;
    private readonly classNames: ClassNames;
    private readonly locale: Locale;
    private readonly rowType: 'preferred' | 'alternative';

    public constructor({
                           element,
                           classNames,
                           locale,
                           rowType = 'alternative',
                       }: {
        element: HTMLRsHeaderElement;
        classNames: ClassNames,
        locale: Locale,
        rowType: 'preferred' | 'alternative'
    }) {
        this.element = element;
        this.classNames = classNames;
        this.locale = locale;
        this.rowType = rowType;
    }

    public showDomains(suggestions: DomainSuggestions): this {
        if (suggestions === undefined) {
            return;
        }
        const suffixes = suggestions.suffixes ?? [];
        this.removeAllRows();
        if (suffixes.length === 0) {
            return;
        }
        this.element.classList.add(this.classNames.searchAlternativeContainerActive);
        suffixes.forEach(alternativeDomain => {
             if (this.rowType === 'preferred') {
                const row = new SearchAlternativeRow({
                    element: this.getTemplate('searchAlternativeRow', 'preferred'),
                    prefix: suggestions.prefix,
                    suffix: alternativeDomain,
                    classNames: this.classNames,
                    locale: this.locale
                });
                wrap(row.element, this.element);
            } else if (this.rowType === 'alternative') {
                const row = new SearchAlternativeRow({
                    element: this.getTemplate('searchAlternativeRow', 'alternative'),
                    prefix: suggestions.prefix,
                    suffix: alternativeDomain,
                    classNames: this.classNames,
                    locale: this.locale
                });
                wrap(row.element, this.element);
            }
        });
        return this;
    }

    private removeAllRows(): void {
        this.element.querySelectorAll(`.${ this.classNames.searchAlternativeRow }`).forEach(row => {
            row.remove();
        });
    }

    private getTemplate(template: keyof typeof templates, ...args: any): any {
        return templates[template].call(this, { classNames: this.classNames }, ...args);
    }
}
